import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/packages.css";
import PackageBanner1 from "../assets/banner/package-banner1.jpg";
import PackageBanner2 from "../assets/banner/package-banner2.jpg";
import PackageBanner3 from "../assets/banner/package-banner3.jpg";
import PackageBanner4 from "../assets/banner/package-banner4.jpg";

const Packages = () => {
  const navigate = useNavigate();
  return (
    <section className="packages-section">
      <h1 className="swipe">Corbett Tour Packages</h1>
      <br />
      <div className="packages-container">
        <div className="packages-card swipe">
          <div className="packages-card-banner">
            <img src={PackageBanner1} alt="" />
          </div>
          <h3>Long Weekend Package</h3>
          <div className="packages-card-content">
            <span className="package-price">
              Starting from <span>Rs. 14,999</span>
            </span>
            <ul>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Breakfast, 02 Lunch
                & 02 Dinner
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Nights/03 Days Stay
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Hi-Tea with Snacks(01
                Veg)
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Bonfire with Light
                Music
              </li>
            </ul>
          </div>
          <br />
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Now
          </button>
        </div>
        <div className="packages-card swipe">
          <div className="packages-card-banner">
            <img src={PackageBanner2} alt="" />
          </div>
          <h3>Jungle Safari Package</h3>
          <div className="packages-card-content">
            <span className="package-price">
              Starting from <span>Rs. 20,999</span>
            </span>
            <ul>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Breakfast, 02 Lunch
                & 02 Dinner
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Nights/03 Days Stay
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Hi-Tea with Snacks(01
                Veg)
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Bonfire with Light
                Music
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Jungle Safari
              </li>
            </ul>
          </div>
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Now
          </button>
        </div>
        <div className="packages-card swipe">
          <div className="packages-card-banner">
            <img src={PackageBanner3} alt="" />
          </div>
          <h3>Honeymoon Package</h3>
          <div className="packages-card-content">
            <span className="package-price">
              Starting from <span>Rs. 14,999</span>
            </span>
            <ul>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Breakfast, 02 Lunch
                & 02 Dinner
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Nights/03 Days Stay
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Hi-Tea with Snacks(01
                Veg)
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Bonfire with Light
                Music
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Room Decaoration with
                Cake
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Candle Light Dinner
              </li>
            </ul>
          </div>
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Now
          </button>
        </div>
        <div className="packages-card swipe">
          <div className="packages-card-banner">
            <img src={PackageBanner4} alt="" />
          </div>
          <h3>Delhi - Corbett - Delhi</h3>
          <div className="packages-card-content">
            <span className="package-price">
              Starting from <span>Rs. 26,999</span>
            </span>
            <ul>
              <li>
                <i class="fa-solid fa-circle-check"></i> Welcome Drinks on
                Arrival
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> 02 Night Acommodation
                at Resort
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Daily Breakfast, Lunch
                & Dinner
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> All transfers &
                Sightseeing by Dzire/Etios
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Jeep Safari
              </li>
              <li>
                <i class="fa-solid fa-circle-check"></i> Ex Delhi
              </li>
            </ul>
          </div>
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Book Now
          </button>
        </div>
      </div>
    </section>
  );
};

export default Packages;
