import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import Packages from '../components/packages';

const PackagesPage = () => {
    return (
        <>
            <Helmet>
                <title>Exclusive Packages at Vanasthali Jungle Resort</title>
                <meta name="description" content="Discover exciting packages at Vanasthali Jungle Resort in Jim Corbett. Enjoy curated wildlife experiences, adventure activities, and luxurious stays with exclusive deals and offers." />
                <meta name="keywords" content="Vanasthali Jungle Resort packages, Jim Corbett packages, adventure packages Corbett, wildlife tour packages, resort deals Corbett, holiday packages Jim Corbett, jungle safari packages, luxury stay packages, family packages, eco-tourism offers Corbett" />
                <link rel="canonical" href="https://vanasthalicorbett.com/packages" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Exclusive Packages at Vanasthali Jungle Resort",
                        "telephone": "+91 8510934101",
                        "url": "https://vanasthalicorbett.com/packages"
                    })}
                </script>
            </Helmet>
            <TopBanner />
            <Navbar />
            <Packages />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default PackagesPage;
