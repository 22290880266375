import React from "react";
import "../css/accommodation.css";
import AccommodationImg1 from "../assets/accommodation/accommodation1.webp";
import AccommodationImg2 from "../assets/accommodation/accommodation2.webp";
import AccommodationImg3 from "../assets/accommodation/accommodation3.webp";
import AccommodationImg4 from "../assets/accommodation/accommodation4.webp";

const Accommodation = () => {
  return (
    <section className="accommodation-section">
      <h1>Accommodation</h1>
      <br />
      <div className="accommodation-container">
        <div className="accommodation-card">
          <img src={AccommodationImg1} alt="" />
          <div className="accommodation-card-content">
            <h4>Luxury Villa</h4>
            <button>Explore</button>
          </div>
        </div>
        <div className="accommodation-card">
          <img src={AccommodationImg2} alt="" />
          <div className="accommodation-card-content">
            <h4>Family Cottage</h4>
            <button>Explore</button>
          </div>
        </div>
        <div className="accommodation-card">
          <img src={AccommodationImg3} alt="" />
          <div className="accommodation-card-content">
            <h4>Luxury Cottage</h4>
            <button>Explore</button>
          </div>
        </div>
        <div className="accommodation-card">
          <img src={AccommodationImg4} alt="" />
          <div className="accommodation-card-content">
            <h4>Wooden Cottage</h4>
            <button>Explore</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accommodation;
