import React from "react";
import "../css/what-we-offer.css";
import WhatWeOfferImg1 from "../assets/gallery/37.jpg";
import WhatWeOfferImg2 from "../assets/what-we-offer/restaurant.webp";
import WhatWeOfferImg3 from "../assets/gallery/47.jpeg";
import WhatWeOfferImg4 from "../assets/what-we-offer/safari.webp";

const WhatWeOffer = () => {
  return (
    <section className="what-we-offer-section">
      {/* Background Image */}
      <div className="deer-background"></div>
      <h1>What We Offer</h1>
      <br />
      <div className="what-we-offer-container">
        <div className="what-we-offer-card">
          <img
            src={WhatWeOfferImg1}
            alt="Activities"
            loading="lazy"
            srcSet={`${WhatWeOfferImg1} 600w, ${WhatWeOfferImg1} 1200w`}
            sizes="(max-width: 600px) 600px, 1200px"
          />
          <div className="what-we-offer-card-content">
            <h3>Activities</h3>
          </div>
        </div>
        <div className="what-we-offer-card">
          <img src={WhatWeOfferImg2} alt="Restaurant" loading="lazy" />
          <div className="what-we-offer-card-content">
            <h3>Restaurant</h3>
          </div>
        </div>
        <div className="what-we-offer-card">
          <img src={WhatWeOfferImg3} alt="Swimming Pool" loading="lazy" />
          <div className="what-we-offer-card-content">
            <h3>Swimming Pool</h3>
          </div>
        </div>
        <div className="what-we-offer-card">
          <img src={WhatWeOfferImg4} alt="Safari" loading="lazy" />
          <div className="what-we-offer-card-content">
            <h3>Safari</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
