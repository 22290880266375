import React from "react";
import "../css/room-comp.css";
import RoomImg1 from "../assets/accommodation/accommodation1.webp";
import RoomImg2 from "../assets/accommodation/accommodation2.webp";
import RoomImg3 from "../assets/accommodation/accommodation3.webp";
import RoomImg4 from "../assets/accommodation/accommodation4.webp";

const RoomComp = () => {
  return (
    <section className="room-comp-section">
      <h1 className="swipe">Our Rooms</h1>
      <br />
      <div className="room-comp-container">
        <div className="room-comp-card swipe">
          <div className="room-comp-card-image">
            <img src={RoomImg1} alt="" />
          </div>
          <div className="room-comp-card-content">
            <h3 className="room-comp-card-title">Luxury Villa</h3>
            <p>
              Indulge in luxury with a dream getaway featuring stunning
              amenities, breathtaking views, and timeless elegance for an
              unforgettable experience.
            </p>
            <button>Explore Now</button>
          </div>
        </div>
        <div className="room-comp-card swipe">
          <div className="room-comp-card-image">
            <img src={RoomImg2} alt="" />
          </div>
          <div className="room-comp-card-content">
            <h3 className="room-comp-card-title">Family Cottage</h3>
            <p>
              Create lasting memories in a cozy family cottage, offering
              comfort, warmth, and beautiful surroundings for the perfect
              getaway.
            </p>
            <button>Explore Now</button>
          </div>
        </div>
        <div className="room-comp-card swipe">
          <div className="room-comp-card-image">
            <img src={RoomImg3} alt="" />
          </div>
          <div className="room-comp-card-content">
            <h3 className="room-comp-card-title">Luxury Cottage</h3>
            <p>
              Experience ultimate relaxation in a luxury cottage, where comfort
              meets elegance amidst breathtaking views and top-tier amenities.
            </p>
            <button>Explore Now</button>
          </div>
        </div>
        <div className="room-comp-card swipe">
          <div className="room-comp-card-image">
            <img src={RoomImg4} alt="" />
          </div>
          <div className="room-comp-card-content">
            <h3 className="room-comp-card-title">Wooden Cottage</h3>
            <p>
              Escape to a charming wooden cottage, where rustic charm meets
              modern comfort, offering a serene retreat in nature's embrace.
            </p>
            <button>Explore Now</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoomComp;
