import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/introduction/introduction.webp";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Riverside Luxury Resort</h1>
        </h1>
        <p>
          Vanasthali Jungle Resort Jim Corbett, Ramnagar offers air-conditioned
          accommodation in Ramnagar. Among the facilities of this property are a
          restaurant, a 24-hour front desk and room service, along with free
          WiFi. Free private parking is available and the resort also features
          car hire for guests who want to explore the surrounding area. All
          rooms at the resort are fitted with a seating area. At Vanasthali
          Resort Jim Corbett, Ramnagar the rooms are fitted with a desk, a
          flat-screen TV and a private bathroom. Guests at the accommodation can
          enjoy a continental breakfast. Vanasthali Resort Jim Corbett, Ramnagar
          offers a children's playground. Guests at the resort will be able to
          enjoy activities in and around Rmnagar, like cycling. Vanasthali
          Resort is located middest in jungle ,And it is Around 1.5 Km From main
          Road through 9 Meter wide Road. And sessional River around 300 Meter
          from Resort at walking distance. The nearest airport is Pantnagar
          Airport, 46 km from Vanasthali Resort Jim Corbett, Ramnagar.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="Riverside Luxury Resort"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
